export const lightbulb = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 6.33331C11.3333 6.33331 9 9.99998 9 13.3333C9 18.6666 13 19.3333 13 21.3333V24.334C13 25.0704 13.5969 25.6666 14.3333 25.6666H17.6667C18.4031 25.6666 19 25.0704 19 24.334V21.3333C19 19.3333 23 18.6666 23 13.3333C23 9.99998 20.6667 6.33331 16 6.33331Z" fill="black" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.333 22.3333H18.6663H13.333Z" fill="#C4C4C4"/>
    <path d="M13.333 22.3333H18.6663" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 20L26 22" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16 1V4" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M27 14H30" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M3 14H6" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M8 7L6 5" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M24 7L26 5" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M9 20L6 22" stroke="black" stroke-width="1.59831" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M15.3399 8C15.3399 8 10.4291 8.70234 10 13.4314" stroke="white" stroke-width="1.19873" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>`;

export const truck = `
<svg width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.2502 15.25H4.75V4.75H15.2502V15.25Z"/>
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 17.5C9.25 18.4665 8.4665 19.25 7.5 19.25C6.5335 19.25 5.75 18.4665 5.75 17.5C5.75 16.5335 6.5335 15.75 7.5 15.75C8.4665 15.75 9.25 16.5335 9.25 17.5Z"/>
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.25 17.5C18.25 18.4665 17.4665 19.25 16.5 19.25C15.5335 19.25 14.75 18.4665 14.75 17.5C14.75 16.5335 15.5335 15.75 16.5 15.75C17.4665 15.75 18.25 16.5335 18.25 17.5Z"/>
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.2502 15.25H15.25V8.75H16.2502C17.9071 8.75 19.2502 10.0931 19.2502 11.75V15.25Z"/>
</svg>`;
