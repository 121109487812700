
import { Order } from "@/models/entities/order.interface";
import { DataTable } from "@/models/utils/datatable.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { Action } from "@/models/utils/table-action.interface";
import { orders } from "@/services/api/orders.service";
import { Component, Vue } from "vue-property-decorator";
import OverlayModule from "@/store/modules/overlay.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import SnackbarModule from "@/store/modules/snackbar.module";
import { OrderProduct } from "@/models/entities/order-product.interface";
import { OrderStatus } from "@/models/utils/order-status.enum";
import html2pdf from "html2pdf.js";
import { truck } from "@/icons";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Orders extends Vue {
  truck = truck;

  private filters = {
    status: OrderStatus.NONE,
    fromDate: new Date().addDays(-30),
    toDate: new Date(),
  };
  private filtersMenu = false;
  private search = "";
  private dialogDelete = false;
  private onDeleteMessage = "";
  private orderToDelete: number = null;
  private dialogConfirm = false;
  private onConfirmMessage = "";
  private orderToConfirm: number = null;
  private dialogMail = false;
  private onSendMessage = "";
  private orderDetails = {
    show: false,
    title: "",
    icon: "",
    order: {} as Order,
    products: [] as OrderProduct[],
  };
  private headers: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Cliente",
      align: "start",
      filterable: true,
      value: "customerName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Data ordine",
      align: "start",
      filterable: true,
      value: "confirmationDate",
      cellType: CellType.DATE,
      isPrimary: false,
      customProp: "isConfirmed",
      class: "llDataTable_header",
    },
    {
      text: "Totale ordine",
      align: "start",
      filterable: true,
      value: "orderTotal",
      cellType: CellType.PRICE,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Da spedire",
      align: "start",
      filterable: true,
      value: "toDeliver",
      cellType: CellType.BOOLICON,
      isPrimary: false,
      customProp: "toDeliver",
      class: "llDataTable_header",
      svg: truck,
    },
    {
      text: "Stato ordine",
      align: "start",
      filterable: true,
      value: "isConfirmed",
      cellType: CellType.STATUS,
      isPrimary: false,
      customProp: "isCanceled",
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      customProp: "isConfirmed",
      class: "llDataTable_header",
    },
  ];
  public items: DataTable<unknown> = {
    key: "id",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [
      { id: Action.VIEW, name: "view" },
      { id: Action.MAIL, name: "mail" },
      { id: Action.CONFIRMORDER, name: "confirm-order" },
      { id: Action.REJECTORDER, name: "reject-order" },
    ],
  };

  async created(): Promise<void> {
    await this.getTableItems();
  }

  async getTableItems(): Promise<void> {
    overlayModule.showOverlay();
    const r = await orders.getAllOrders(
      this.filters.fromDate,
      this.filters.toDate,
      this.filters.status != null && this.filters.status != undefined
        ? this.filters.status
        : OrderStatus.NONE
    );
    this.items.loading = false;
    this.$set(
      this.items,
      "values",
      r.data.sort((a, b) => (a.confirmationDate <= b.confirmationDate ? 1 : -1))
    );
  }

  private async downloadPDF(): Promise<void> {
    overlayModule.showOverlay();
    const pdf = await orders.getPDF(this.filters.fromDate, this.filters.toDate);
    const opt = {
      margin: 12,
      filename: "Ordini.pdf",
    };
    const element = pdf.data[0] + pdf.data[1];
    html2pdf().set(opt).from(element).save();
  }

  private onSearch(value: string): void {
    this.$set(this.items, "search", value);
  }

  private async showOrderDialog(item: Order): Promise<void> {
    this.$set(this.orderDetails, "icon", "mdi-clipboard-text");
    this.$set(this.orderDetails, "title", "Dettagli ordine");
    await Promise.all([this.getOrder(item.id), this.getProducts(item.id)]);
    this.$set(this.orderDetails, "show", true);
  }

  private async getOrder(id: number): Promise<void> {
    overlayModule.showOverlay();
    const order = await orders.getOrderByID(id);
    this.$set(this.orderDetails, "order", order.data);
  }

  private async getProducts(id: number): Promise<void> {
    overlayModule.showOverlay();
    const products = await orders.getOrderProductsByID(id);
    this.$set(
      this.orderDetails,
      "products",
      products.data.map((el, idx) => {
        el.index = idx;
        return el;
      })
    );
  }

  private closeOrderDetails(): void {
    this.$set(this.orderDetails, "show", false);
  }

  private async openMailClient(item: Order): Promise<void> {
    const emailBody = await orders.sendConfirmationEmail(item.id);
    window.open(
      "mailto:" +
        (item.createdByAdmin == false && item.userEmail ? item.userEmail : "") +
        " ?subject=Ordine%20confermato%20-%20#" +
        item.id +
        "&body=" +
        encodeURIComponent(emailBody.data)
    );
  }

  private async onConfirm(item: Order): Promise<void> {
    this.orderToConfirm = item.id;
    this.onConfirmMessage =
      "Sei sicuro di volere confermare l'ordine " + item.id + " ?";
    this.dialogConfirm = true;
  }

  private async confirmOrder(): Promise<void> {
    this.dialogConfirm = false;
    overlayModule.showOverlay();
    await orders.confirmOrder(this.orderToConfirm);
    this.getTableItems();
    snackbarModule.showSnackbar({
      message: "Ordine confermato con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private async onReject(item: Order): Promise<void> {
    this.orderToDelete = item.id;
    this.onDeleteMessage =
      "Sei sicuro di volere respingere l'ordine " + item.id + " ?";
    this.dialogDelete = true;
  }

  private async deleteOrder(): Promise<void> {
    this.dialogDelete = false;
    overlayModule.showOverlay();
    await orders.rejectOrder(this.orderToDelete);
    this.getTableItems();
    snackbarModule.showSnackbar({
      message: "Ordine respinto con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }
}
